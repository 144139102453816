<template>
  <div class="card full-width">

    <div class="card-header border-0 pt-6 full-width">
      <!--begin::Card title-->

      <div class="row full-width">

        <div class="col-md-4 fv-row">
          <label class="fs-6 fw-bold mb-2">Status</label>
          <el-select clearable v-model="filters.status" class="m-2 select-display-block" placeholder="Select Status" size="large">
            <el-option v-for="item in filterOptions.statuses" :label="item.label" :value="item.value" :key="item.value"></el-option>
          </el-select>
        </div>
        <div class="col-md-4 fv-row">
          <label class="fs-6 fw-bold mb-2">User</label>
          <el-select v-model="filters.user_id" class="m-2 select-display-block" clearable filterable remote reserve-keyword placeholder="Search for users" :remote-method="getRemoteUsers" :loading="remoteUsersLoading">
            <el-option v-for="item in filterOptions.users" :label="item.first_name+ ' '+item.last_name" :key="item.id" :value="item.id"/>
          </el-select>
        </div>
        <div class="col-md-4 fv-row">
          <label class="fs-6 fw-bold mb-2">Provider</label>
          <el-select v-model="filters.provider_id" class="m-2 select-display-block" clearable filterable remote reserve-keyword placeholder="Search for providers" :remote-method="getRemoteProviders" :loading="remoteProvidersLoading">
            <el-option v-for="item in filterOptions.providers" :label="item.first_name+ ' '+item.last_name" :key="item.id" :value="item.id"/>
          </el-select>
        </div>
      </div>
      <div class="row full-width">
        <div class="col-md-4 fv-row">
          <label class="fs-6 fw-bold mb-2">Service</label>
          <el-select v-model="filters.service_id" class="m-2 select-display-block" clearable filterable remote reserve-keyword placeholder="Search for services" :remote-method="getRemoteServices" :loading="remoteServicesLoading">
            <el-option v-for="item in filterOptions.services" :label="item.name" :key="item.id" :value="item.id"/>
          </el-select>
        </div>
        <div class="col-md-4 fv-row">
          <label class="fs-6 fw-bold mb-2">Price Type</label>
          <el-select clearable v-model="filters.price_type_id" class="m-2 select-display-block" placeholder="Select Price Type" size="large">
            <el-option v-for="item in filterOptions.price_types" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </div>
        <div class="col-md-4 fv-row">
          <label class="required fs-6 fw-bold mb-2 ">Start & End Date</label>
          <el-date-picker
              class="date-range-filter"
              v-model="filters.date"
              type="daterange"
              range-separator="To"
              start-placeholder="Start date"
              end-placeholder="End date"
              value-format="YYYY-MM-DD"
          />
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <Datatable
          class="table-button-actions-width"
          :total="total"
          :rowsPerPage="perPage"
          :loading="loading"
          :table-data="tableData"
          :table-header="tableHeader"
          :enable-items-per-page-dropdown="true"
          @current-change="onCurrentChange"
          @sort="sort"
          @items-per-page-change="changePerPage"
      >
        <template v-slot:cell-id="{ row: data }">{{ data.id }}</template>
        <template v-slot:cell-user="{ row: data }">{{ data.user }}</template>
        <template v-slot:cell-provider="{ row: data }">{{ data.provider ? data.provider : "-" }}</template>
        <template v-slot:cell-service="{ row: data }">{{ data.service }}</template>
        <template v-slot:cell-price_type="{ row: data }">
          <span class="badge badge-success">{{ data.price_type }}</span>
        </template>
        <template v-slot:cell-status="{ row: data }">
          <span class="badge badge-primary">{{ data.status }}</span>
        </template>
        <template v-slot:cell-total_price="{ row: data }">{{ data.total_price ? data.total_price : "-" }}</template>
        <template v-slot:cell-date="{ row: data }">{{ data.date }}</template>
        <template v-slot:cell-actions="{ row: data }">
          <el-row>
            <el-button v-can="'orders.show'" type="default" size="small" @click="viewResource(data.id)" :icon="View" circle></el-button>
          </el-row>
        </template>

      </Datatable>
    </div>
  </div>
</template>

<script>
import {defineComponent, ref, onMounted, watch, reactive} from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import ApiAxiosService from "@/core/services/ApiAxiosService";
import {Search, Edit, Delete, View} from '@element-plus/icons-vue'
import {handleError, handleSuccess} from "@/core/helpers/response";
import {APIs} from "@/store/enums/ApiEnums";
import CheckboxTableHeader from "@/components/admin/general/CheckboxTableHeader";
import {confirmDelete, confirmGeneralMessage} from "@/core/helpers/custom_notification";
import {useRouter, useRoute} from 'vue-router'
import Swal from "sweetalert2/dist/sweetalert2.js";
import {getProviders, getServices, getUsers} from "@/core/helpers/remote_data";

export default defineComponent({
  name: "orders-list",
  components: {
    Datatable, CheckboxTableHeader
  },

  setup() {
    const router = useRouter();
    const tableHeader = ref([
      {name: "ID", key: "id", sortable: true,},
      {name: "User", key: "user", sortable: false,},
      {name: "Provider", key: "provider", sortable: true,},
      {name: "Service", key: "service", sortable: true,},
      {name: "Price Type", key: "price_type", sortable: true,},
      {name: "Status", key: "status", sortable: true,},
      {name: "Total Price", key: "total_price", sortable: true,},
      {name: "Date", key: "date", sortable: true,},
      {name: "Actions", key: "actions",},
    ]);

    const tableData = ref([]);
    const loading = ref(true);
    const total = ref(0);
    const currentPage = ref(1);
    const perPage = ref(0);
    const filters = ref({
      search: '',
      status: '',
      user_id : '',
      provider_id : '',
      service_id : '',
      price_type_id : '',
      date : '',
      page: 1,
      per_page: 10
    });
    const remoteUsersLoading = ref(false);
    const remoteServicesLoading = ref(false);
    const remoteProvidersLoading = ref(false);

    const filterOptions = ref({statuses: [],price_types : [] , users : []  , providers : [] , services : []});

    onMounted(() => {
      setCurrentPageBreadcrumbs("Orders", []);
    });

    const fetchFilerOptions = async () => {
      try {
        let response = await ApiAxiosService.get(APIs.ORDER.filter_options);
        filterOptions.value.statuses        = response.data.data.statuses;
        filterOptions.value.price_types     = response.data.data.price_types;
      } catch (e) {
        handleError(e)
      }
    }
    const fetchData = async () => {
      loading.value = true;
      try {
        filters.page = currentPage.value;
        let response = await ApiAxiosService.get(APIs.ORDER.list, filters.value);
        let data = response.data.data;
        tableData.value = data.orders;
        total.value = data.pagination_options.last_page;
        currentPage.value = data.pagination_options.currentPage;
        perPage.value = 1;
        loading.value = false;
      } catch (error) {
        handleError(error)
      }

    };

    const onCurrentChange = (value) => {
      tableData.value = [];
      filters.value.page = value;
    };
    const changePerPage = (value) => {
      filters.value.per_page = value;
    }

    const sort = (data) => {
    }
    watch(filters.value, (currentValue, oldValue) => {
      fetchData();
    });


    const getRemoteUsers = async (query) => {

      if (query) {
        remoteUsersLoading.value = true
        filterOptions.value.users = await getUsers(query);
        remoteUsersLoading.value = false;
      } else {
        filterOptions.value.users = [];
      }
    }
    const getRemoteProviders = async (query) => {

      if (query) {
        remoteProvidersLoading.value = true
        filterOptions.value.providers = await getProviders(query);
        remoteProvidersLoading.value = false;
      } else {
        filterOptions.value.providers = [];
      }
    }
    const getRemoteServices = async (query) => {

      if (query) {
        remoteServicesLoading.value = true
        filterOptions.value.services = await getServices(query);
        remoteServicesLoading.value = false;
      } else {
        filterOptions.value.services = [];
      }
    }
    const viewResource = (id) => {
      router.push({name: 'view-order', params: {id: id}});
    }
    fetchData();
    fetchFilerOptions();

    return {
      tableData,
      tableHeader,
      loading,
      fetchData,
      total,
      perPage,
      onCurrentChange,
      changePerPage,
      filters,
      sort,
      fetchFilerOptions,
      filterOptions,
      viewResource,
      Search, Edit, Delete, View,

      getRemoteUsers,
      remoteUsersLoading,

      getRemoteServices,
      remoteServicesLoading,

      getRemoteProviders,
      remoteProvidersLoading
    };
  },

});
</script>
<style>
.date-range-filter {
  margin-left: 7px;
  width: 97%!important;
}
</style>
